[
  {
    "name": "juno",
    "ownerAddress": "junovaloper1za6z32wkgcuyues2z3yjjds6gvew8qrgfd684l",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "evmos",
    "txTimeout": 120000,
    "maxPerDay": 1,
    "gasPrice": "75000000000aevmos",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "secretnetwork",
    "ownerAddress": "secretvaloper1x6efs0le7f9f3r4u2rq4jykcjunsq4us99p7xu",
    "authzAminoGenericOnly": false
  },
  {
    "name": "kyve",
    "ownerAddress": "kyvevaloper1wna5mpsje840nz9hewdhajnu7v4347awtchru2",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "irisnet",
    "ownerAddress": "iva177aauu7fr6l7xw0k3m06lu9h5xenxrlj9cshp9"
  },
  {
    "name": "cosmoshub"
  },
  {
    "name": "osmosis",
    "maxPerDay": 1,
    "apiVersions": {
      "gov": "v1beta1"
    },
    "authzAminoExecPreventTypes": [
      "/cosmos.gov."
    ]
  },
  {
    "name": "akash"
  },
  {
    "name": "chihuahua",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "gravitybridge"
  },
  {
    "name": "regen",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "terra",
    "authzAminoLiftedValues": false
  },
  {
    "name": "terra2"
  },
  {
    "name": "sentinel"
  },
  {
    "name": "dig"
  },
  {
    "name": "bitcanna"
  },
  {
    "name": "emoney"
  },
  {
    "name": "kava",
    "ownerAddress": "kavavaloper1hj99pyu4pyun8p22pyd8nu5v5g2rdd2l0d5c5z"
  },
  {
    "name": "zetachain",
    "ownerAddress": "zetavaloper1sgvmu56yg3rtpyylrqmvhse2waswn6j3j3l074"
  },
  {
    "name": "desmos",
    "authzAminoGenericOnly": false
  },
  {
    "name": "cryptoorgchain",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "sifchain"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze"
  },
  {
    "name": "comdex",
    "apiVersions": {
      "gov": "v1beta1"
    },
    "authzAminoExecPreventTypes": [
      "/cosmos.gov.",
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ],
    "authzAminoGenericOnly": true
  },
  {
    "name": "cheqd"
  },
  {
    "name": "umee"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric"
  },
  {
    "name": "impacthub"
  },
  {
    "name": "kichain"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation"
  },
  {
    "name": "fetchhub"
  },
  {
    "name": "cerberus"
  },
  {
    "name": "bostrom"
  },
  {
    "name": "starname"
  },
  {
    "name": "rizon"
  },
  {
    "name": "decentr"
  },
  {
    "name": "assetmantle"
  },
  {
    "name": "crescent"
  },
  {
    "name": "meme"
  },
  {
    "name": "cronos",
    "enabled": false
  },
  {
    "name": "harpoon",
    "testnet": true
  },
  {
    "name": "kujira"
  },
  {
    "name": "genesisl1",
    "txTimeout": 120000
  },
  {
    "name": "tgrade",
    "enabled": false
  },
  {
    "name": "aioz",
    "enabled": false
  },
  {
    "name": "echelon"
  },
  {
    "name": "kichaintestnet",
    "testnet": true
  },
  {
    "name": "likecoin"
  },
  {
    "name": "passage"
  },
  {
    "name": "stride",
    "authzSupport": false
  },
  {
    "name": "pulsar",
    "testnet": true
  },
  {
    "name": "teritori"
  },
  {
    "name": "rebus"
  },
  {
    "name": "jackal"
  },
  {
    "name": "oraichain"
  },
  {
    "name": "acrechain"
  },
  {
    "name": "mars",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "planq"
  },
  {
    "name": "injective",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "xpla"
  },
  {
    "name": "quicksilver",
    "maxPerDay": 1,
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "chain4energy"
  },
  {
    "name": "coreum"
  },
  {
    "name": "nois"
  },
  {
    "name": "omniflixhub"
  },
  {
    "name": "empowerchain",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "qwoyn"
  },
  {
    "name": "neutron",
    "enabled": false
  },
  {
    "name": "dydx"
  },
  {
    "name": "celestia"
  }
]
